import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewVideo,
  updateVideoById,
  viewVideoById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import VideoTypes from '../form-elements/video-types.component';
import Categories from '../form-elements/categories.component';
import {
  CONTENT_ALIAS,
  CONTENT_VIDEOS_SKU,
  MULTIPLE_CAT_PER_VIDEO,
  VIDEO_TYPES,
} from '../../config/const.config';
import TestimonialCategories from '../form-elements/testimonial-category.component';
import TestimonialTabs from '../form-elements/testimonial-tab.component';
import TransmissionOrgan from '../form-elements/transmission-organ.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditVideos = ({ title, dataId, onCancel, onSuccess, options }) => {
  const {
    control,
    formState: { errors },
    watch,
    unregister,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      category: [],
      description: '',
      purchaseURL: '',
      status: null,
      duration: null,
      videoUrl: '',
      thumbnail: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [programId, setProgramId] = useState(0);
  const programWatch = watch('category');
  const videoTypeWatch = watch('videoType');

  const saveNewData = (payload) => {
    createNewVideo(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.VIDEO_SINGULAR} added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateVideoById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.VIDEO_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const categoriesIdStrArr = [];
    if (!MULTIPLE_CAT_PER_VIDEO) {
      categoriesIdStrArr.push(data.category);
    } else if (MULTIPLE_CAT_PER_VIDEO) {
      if (typeof data.categories === 'object') {
        data.categories.forEach((item) => {
          const spiltData = item.split('] -');
          const categoryId = spiltData[0].slice(1);

          categoriesIdStrArr.push(`${categoryId}`);
        });
      }
    }

    const payload = {
      title: data.title || '',
      description: data.description || '',
      category: categoriesIdStrArr || [],
      videoType: data.videoType || '',
      transmissionOrganID: data.transmissionOrganID || 0,
      testimonialTabID: data.testimonialTabID || 0,
      testimonialCategoryID: data.testimonialCategoryID || 0,
      purchaseURL: data.purchaseURL || '',
      status: data.status,
      duration: data.duration || 0,
      videoUrl: data.videoUrl || '',
      thumbnail: data.thumbnail || '',
      heroImage: data.heroImage || '',
      multipleCatPerVideo: MULTIPLE_CAT_PER_VIDEO,
    };

    if (payload.videoType === VIDEO_TYPES[1]) {
      payload.videoType = `${VIDEO_TYPES[1]}s`;
    }

    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (videoTypeWatch === VIDEO_TYPES[0]) {
      setValue('category', 1);

      unregister('transmissionOrganID');
      unregister('testimonialTabID');
      unregister('testimonialCategoryID');
    } else if (
      videoTypeWatch === VIDEO_TYPES[1] ||
      videoTypeWatch === VIDEO_TYPES[3]
    ) {
      setValue('category', 1);

      if (videoTypeWatch === VIDEO_TYPES[3]) {
        unregister('purchaseURL');
      }

      unregister('testimonialTabID');
      unregister('testimonialCategoryID');
    } else if (videoTypeWatch === VIDEO_TYPES[2]) {
      setValue('category', null);

      unregister('category');
      unregister('transmissionOrganID');
    }
  }, [videoTypeWatch]);

  useEffect(() => {
    setProgramId(programWatch);
  }, [programWatch]);

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewVideoById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('description', res.data?.description || '');
        setValue('videoType', res.data?.videoType || null);
        setValue('category', res.data?.category[0]?.categoryId || null);
        setValue('transmissionOrganID', res.data?.transmissionOrganID || 0);
        setValue('purchaseURL', res.data?.purchaseURL || '');
        setValue('status', res.data?.status || 0);
        setValue('duration', res.data?.duration || 0);
        setValue('videoUrl', res.data?.videoUrl || '');
        setValue('thumbnail', res.data?.thumbnail || '');
        setValue('heroImage', res.data?.heroImage || '');
        setValue('videoType', res.data?.videoType || '');
        setValue('testimonialTabID', res.data?.testimonialTabID || 0);
        setValue('testimonialCategoryID', res.data?.testimonialCategoryID || 0);

        if (
          res.data?.videoType &&
          res.data?.videoType === `${VIDEO_TYPES[1]}s`
        ) {
          setValue('videoType', VIDEO_TYPES[1]);
        }

        setProgramId(res.data?.category[0]?.categoryId || 0);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.VIDEO_SINGULAR} Title`}
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  // rules={{ ...maxLengthValidation(1000) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      multiline
                      maxRows={2}
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      // inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="videoType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <VideoTypes
                      id="videoType"
                      name="videoType"
                      label="Video Type"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1, 2, 3]}
                      sx={{ width: '100%' }}
                      error={errors?.videoType?.message || ''}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>

              {!MULTIPLE_CAT_PER_VIDEO && videoTypeWatch !== VIDEO_TYPES[2] && (
                <Grid item xs={12} sm={6} md={6} sx={{ display: 'none' }}>
                  <Controller
                    name="category"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Categories
                        id="category"
                        name="category"
                        label={`Select ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.category?.message || ''}
                        options={options}
                        disabled={!!dataId}
                      />
                    )}
                  />
                </Grid>
              )}

              {(videoTypeWatch === VIDEO_TYPES[1] ||
                videoTypeWatch === VIDEO_TYPES[3]) && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="transmissionOrganID"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <TransmissionOrgan
                        id="transmissionOrganID"
                        name="transmissionOrganID"
                        label={`Select ${CONTENT_VIDEOS_SKU.ALIAS_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.transmissionOrganID?.message || ''}
                        options={options}
                        programId={programId}
                      />
                    )}
                  />
                </Grid>
              )}

              {videoTypeWatch === VIDEO_TYPES[1] && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="purchaseURL"
                    control={control}
                    rules={{
                      ...validationRules.OPTIONAL_URL,
                      ...validationRules.REQUIRED,
                      ...{ ...maxLengthValidation(500) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Purchase URL"
                        type="text"
                        error={!!errors.purchaseURL}
                        helperText={errors?.purchaseURL?.message || null}
                        fullWidth
                        inputProps={{ maxLength: 500 }}
                      />
                    )}
                  />
                </Grid>
              )}

              {videoTypeWatch === VIDEO_TYPES[2] && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="testimonialTabID"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <TestimonialTabs
                        id="testimonialTabID"
                        name="testimonialTabID"
                        label="Select Testimonial Tab"
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.testimonialTabID?.message || ''}
                        options={options}
                      />
                    )}
                  />
                </Grid>
              )}

              {videoTypeWatch === VIDEO_TYPES[2] && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="testimonialCategoryID"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <TestimonialCategories
                        id="testimonialCategoryID"
                        name="testimonialCategoryID"
                        label="Select Testimonial Category"
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.testimonialCategoryID?.message || ''}
                        options={options}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Divider
                  textAlign="center"
                  variant="fullWidth"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${CONTENT_ALIAS.VIDEO_SINGULAR} Details (You can upload video and images from upload section)`}
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="videoUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Video Url"
                      type="text"
                      error={!!errors.videoUrl}
                      helperText={errors?.videoUrl?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="duration"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Duration (In Seconds)"
                      type="number"
                      error={!!errors.duration}
                      helperText={errors?.duration?.message || null}
                      fullWidth
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="thumbnail"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Thumbnail Url"
                      type="text"
                      error={!!errors.thumbnail}
                      helperText={errors?.thumbnail?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditVideos.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default AddEditVideos;
