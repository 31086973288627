import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import {
  getProgramTransmissionOrgans,
  getSearchListTransmissionOrgans,
  getSubscribedTransmissionOrgans,
} from '../../services/content.service';
import { formatTimestamp } from '../../utils/datetime.util';

const TransmissionOrgan = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  error,
  subscriberId,
  programId,
}) => {
  const [booksData, setBooksData] = useState([]);

  useEffect(() => {
    if (subscriberId) {
      getSubscribedTransmissionOrgans(subscriberId)
        .then((res) => {
          setBooksData(res.data);
        })
        .catch(() => {
          // nothing
        });
    } else if (programId && programId !== 0) {
      getProgramTransmissionOrgans(programId)
        .then((res) => {
          setBooksData(res.data);
        })
        .catch(() => {
          // nothing
        });
    } else {
      getSearchListTransmissionOrgans()
        .then((res) => {
          setBooksData(res.data);
        })
        .catch(() => {
          // nothing
        });
    }
  }, [subscriberId, programId]);

  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={name}
        value={defaultValue}
        label={label}
        onChange={onChange}
        sx={sx}
        MenuProps={{ autoFocus: !!defaultValue }}
      >
        {booksData.map((k) => (
          <MenuItem key={`${id}-v-${k.id}`} value={`${k.id}`}>
            {subscriberId
              ? `${k.transmissionOrgan} (${formatTimestamp(
                  k.subscriptionEndDate,
                  'YYYY-MM-DD'
                )})`
              : `${k.name}`}
          </MenuItem>
        ))}
      </Select>
      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

TransmissionOrgan.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  subscriberId: PropTypes.number,
  programId: PropTypes.number,
};
TransmissionOrgan.defaultProps = {
  sx: {},
  error: '',
  subscriberId: 0,
  programId: 0,
};

export default TransmissionOrgan;
