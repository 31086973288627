import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Status from '../form-elements/status.component';
import NotificationType from '../form-elements/notification-type.component';
import { TYPE_LABELS, TYPE_LABELS_KEYS } from '../../config/const.config';
import {
  createNotification,
  updateNotification,
  viewNotification,
} from '../../services/settings.service';

const AddEditNotification = ({ dataId, setDataId, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      type: null,
      buttonText: '',
      actionUrl: '',
      status: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [notificationId, setNotificationId] = useState(dataId || null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNotification(payload)
      .then(() => {
        setFormSubmitted(false);
        onSuccess(`New notification added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateNotification(payload.id, payload)
      .then(() => {
        setFormSubmitted(false);
        onSuccess('Notification details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    if (notificationId) {
      const payload = {
        id: notificationId,
        title: data.title,
        type: TYPE_LABELS[data.type],
        buttonText: data.buttonText || '',
        actionUrl: data.actionUrl || '',
        status: data.status,
      };
      updateExistingData(payload);
    } else {
      const payload = {
        title: data.title,
        type: TYPE_LABELS[data.type],
        buttonText: data.buttonText,
        actionUrl: data.actionUrl,
        status: data.status,
      };
      saveNewData(payload);
    }
  };

  useEffect(() => {
    viewNotification(dataId)
      .then((res) => {
        if (res.data === null) {
          setNotificationId(0);
          setLoading(false);
        } else {
          // Fill form values
          setValue('title', res.data?.title || '');
          setValue('type', TYPE_LABELS_KEYS[res.data?.type]);
          setValue('buttonText', res.data?.buttonText || '');
          setValue('actionUrl', res.data?.actionUrl || '');
          setValue('status', res.data?.status || 0);
          setNotificationId(res.data?.id);
          setDataId(res.data?.id);
          setLoading(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="title"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                  ...{ ...maxLengthValidation(255) },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Notification Title"
                    type="text"
                    error={!!errors.title}
                    helperText={errors?.title?.message || null}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="type"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                  ...{ ...maxLengthValidation(50) },
                }}
                render={({ field: { onChange, value } }) => (
                  <NotificationType
                    id="type"
                    name="type"
                    label="Select Type"
                    defaultValue={value}
                    onChange={onChange}
                    validOptions={[1]}
                    sx={{ width: '100%' }}
                    error={errors?.type?.message || ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="buttonText"
                control={control}
                rules={{
                  ...{ ...maxLengthValidation(80) },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Button Text"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 80 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="actionUrl"
                control={control}
                rules={{
                  ...{ ...maxLengthValidation(255) },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Action URL"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="status"
                control={control}
                rules={validationRules.REQUIRED}
                render={({ field: { onChange, value } }) => (
                  <Status
                    id="sub_status"
                    name="sub_status"
                    label="Status"
                    defaultValue={value}
                    onChange={onChange}
                    validOptions={[1]}
                    sx={{ width: '100%' }}
                    error={errors?.status?.message || ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      )}

      <Button
        type="submit"
        form="add-edit-form"
        color="primary"
        variant="contained"
        disabled={loading || formSubmitted}
        sx={{ mt: 3 }}
      >
        Save
      </Button>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

AddEditNotification.propTypes = {
  dataId: PropTypes.number.isRequired,
  setDataId: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditNotification;
