import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListTransmissionOrgans from '../../components/content/list-transmission-organs.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentTransmissionOrgansView = () => (
  <AppLayout pageTitle={PAGE_TITLE_LIST.CONTENT_VIDEOS_SKU}>
    <ListTransmissionOrgans />
  </AppLayout>
);

ContentTransmissionOrgansView.propTypes = {};

export default ContentTransmissionOrgansView;
