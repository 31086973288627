import { CONTENT_VIDEOS_SKU } from './const.config';

// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  REALTIME_DASHBOARD: '/realtime-dashboard',
  CONTENT_CATEGORIES: '/content/categories',
  CONTENT_VIDEOS: '/content/videos',
  CONTENT_VIDEOS_SKU: `/content/${CONTENT_VIDEOS_SKU.ROUTE_PATH}`,
  CONTENT_ADS: '/content/ads',
  CONTENT_SEQUENCE: '/content/sequence',
  CONTENT_SEQUENCE_VIDEOS: '/content/sequence/videos',
  CONTENT_SEQUENCE_TESTIMONIAL_TABS: '/content/sequence/testimonials/tabs',
  CONTENT_SEQUENCE_TESTIMONIAL_CATEGORIES:
    '/content/sequence/testimonials/categories',
  CONTENT_TABS: '/content/tabs',
  CONTENT_TESTIMONIALS: '/content/testimonials',
  CONTENT_TESTIMONIALS_TABS: '/content/testimonials/tabs',
  CONTENT_TESTIMONIALS_CATEGORIES: '/content/testimonials/categories',
  CONTENT_TESTIMONIALS_TEXT: '/content/testimonials/text-testimonials',
  SUBSCRIBERS: '/subscribers',
  SUBSCRIPTIONS: '/subscriptions',
  ADMIN_USERS: '/users',
  NOTIFICATIONS: '/notifications',
  SALUTATION: '/salutation',
  REPORTS_OVERVIEW_VIDEOS: '/reports/overview/videos',
  REPORTS_LIVE_USERS: '/reports/live-users',
  REPORTS_SESSIONS: '/reports/sessions',
  REPORTS_SUBSCRIPTIONS: '/reports/subscriptions',
  LOGOUT: '/logout',
};

export default ROUTE_PATH;
