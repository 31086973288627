import React, { useEffect, useState } from 'react';
import { Button, Container, Paper, Stack } from '@mui/material';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  getListSequence,
  updateCategoryVideosSequence,
} from '../../services/content.service';
import { CONTENT_ALIAS } from '../../config/const.config';
import ListSequenceVideos from './list-sequence-videos.component';
import Loading from '../table-elements/loading.component';

const ListSequence = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryVideosList, setCategoryVideosList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: null,
    categoryName: '',
  });
  const [catOptions, setCatOptions] = useState({
    error: false,
    loading: true,
  });
  const [vidOptions, setVidOptions] = useState({
    error: false,
    loading: true,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleOnDragEndVideos = (result) => {
    if (result.destination) {
      const newVideos = Array.from(categoryVideosList);
      const [draggedItem] = newVideos.splice(result.source.index, 1);

      newVideos.splice(result.destination.index, 0, draggedItem);
      setCategoryVideosList(newVideos);
    }
  };

  const handleUpdateVideosSequence = () => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    setVidOptions({ ...vidOptions, loading: true });

    const updatedVideosCategorySequence = categoryVideosList.map(
      (video) => video.video_id
    );

    const payload = {
      categoryID: selectedCategory.id,
      newSequence: updatedVideosCategorySequence,
    };

    updateCategoryVideosSequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: `${CONTENT_ALIAS.VIDEO_PLURAL} sequence updated successfully.`,
          });

          setVidOptions({ ...vidOptions, loading: false });
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong.',
        });
      });
  };

  const handleVideoReordering = (category) => {
    setSelectedCategory({
      ...selectedCategory,
      id: category.id,
      categoryName: category.name,
    });

    let uri = 'video-category';
    uri += `?categoryID=${category.id}`;

    getListSequence(uri)
      .then((res) => {
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: false,
        });
        setCategoryVideosList(res.data);
      })
      .catch(() =>
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: true,
        })
      );
  };

  const getSequenceData = () => {
    const uri = 'category';

    getListSequence(uri)
      .then((res) => {
        setCatOptions({
          ...catOptions,
          loading: false,
          error: false,
        });
        setCategoryList(res.data);
        handleVideoReordering(res.data[0]);
      })
      .catch(() => {
        setCatOptions({
          ...catOptions,
          loading: false,
          error: true,
        });
        setCategoryList([]);
      });
  };

  useEffect(() => {
    getSequenceData();
  }, []);

  return (
    <>
      {catOptions.loading && <Loading />}

      <Stack direction="row" justifyContent="flex-end" sx={{ px: 1, py: 2 }}>
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpdateVideosSequence}
            disabled={
              categoryVideosList.length === 0 || categoryVideosList.length === 1
            }
          >
            Save Video Sequence
          </Button>
        </div>
      </Stack>

      <Container
        maxWidth="false"
        sx={{ maxHeight: '74vh', overflowY: 'auto', paddingX: 0 }}
      >
        {!catOptions.loading && categoryList.length > 0 && (
          <ListSequenceVideos
            handleOnDragEndVideos={handleOnDragEndVideos}
            categoryVideosList={categoryVideosList}
            vidOptions={vidOptions}
          />
        )}

        {!catOptions.loading && categoryList.length === 0 && (
          <Paper
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '10vh',
            }}
          >
            No data available.
          </Paper>
        )}
      </Container>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListSequence.propTypes = {};

export default ListSequence;
