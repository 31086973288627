import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_VIDEOS_SKU } from '../const.config';

// Subscriptions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'profilePic',
    label: '',
    width: '2%',
    align: 'center',
    dataKey: 'profilePic',
    sortable: false,
    type: CELL_DATA_TYPES.PROFILE,
  },
  {
    id: 'email',
    label: 'Subscriber Email',
    width: '15%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'transmissionOrgan',
    label: `${CONTENT_VIDEOS_SKU.ALIAS_SINGULAR}`,
    width: '10%',
    align: 'left',
    dataKey: 'transmissionOrgan',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriptionStartDate',
    label: 'Subscription Start Date',
    width: '10%',
    align: 'left',
    dataKey: 'subscriptionStartDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscriptionEndDate',
    label: 'Subscription End Date',
    width: '10%',
    align: 'left',
    dataKey: 'subscriptionEndDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscriptionAmount',
    label: 'Subscription Amount',
    width: '10%',
    align: 'right',
    dataKey: 'subscriptionAmount',
    sortable: false,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'entryType',
    label: 'Entry Created',
    width: '5%',
    align: 'center',
    dataKey: 'entryType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'playProgress',
    label: 'Play Progress %',
    width: '5%',
    align: 'center',
    dataKey: 'playProgress',
    sortable: false,
    type: CELL_DATA_TYPES.PROGRESS,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  // {
  //   action: ACTIONS.EDIT.value,
  //   label: ACTIONS.EDIT.label,
  //   conditional: (val) => val.entryType === 'MANUAL',
  // },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  // {
  //   action: ACTIONS.DELETE.value,
  //   label: ACTIONS.DELETE.label,
  //   conditional: (val) => val.entryType === 'MANUAL',
  // },
];

export const VIEW_SUBSCRIPTION_COLUMNS = [
  {
    id: 'transmissionOrgan',
    label: `${CONTENT_VIDEOS_SKU.ALIAS_SINGULAR}`,
    dataKey: 'transmissionOrgan',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriptionStartDate',
    label: 'Subscription Start Date',
    dataKey: 'subscriptionStartDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscriptionEndDate',
    label: 'Subscription End Date',
    dataKey: 'subscriptionEndDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscriptionAmount',
    label: 'Subscription Amount',
    dataKey: 'subscriptionAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'entryType',
    label: 'Entry Created',
    dataKey: 'entryType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'playProgress',
    label: 'Play Progress %',
    dataKey: 'playProgress',
    type: CELL_DATA_TYPES.PROGRESS,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
